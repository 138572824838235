import { useKeenSlider } from 'keen-slider/react'
import { HeadingXl, HeadingXs } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'
import { TextSm } from '/features/buildingBlocks/Text'
import { useTranslate } from '/machinery/I18n'
import { Button } from '/features/buildingBlocks/Button'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { routeMap } from '/routeMap'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { trackInteractionSlided } from '/machinery/trackInteractionSlided'

import dotsIcon from '/images/icons/dots.raw.svg'
import arrowRightIcon from '/images/icons/arrow-right-slider.raw.svg'
import arrowLeftIcon from '/images/icons/arrow-left-slider.raw.svg'
import 'keen-slider/keen-slider.min.css'
import media from '/cssGlobal/media.css'
import styles from './SliderWithPages.css'

export function SliderWithPages({ items, title, backgroundColor = null }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)
  const [dragged, setDragged] = React.useState(false)
  const isMd = useMediaQuery(`(min-width: ${media.breakpointMd})`)
  const isLg = useMediaQuery(`(min-width: ${media.breakpointLg})`)

  const slides = {
    perView: isLg ? 3 : (isMd ? 2 : 1.3),
    spacing: isLg ? 60 : 20,
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    slides,
    initial: 0,
    dragged() {
      setDragged(true)
    },
    animationEnded() {
      setDragged(false)
    },
    dragEnded(s) {
      trackInteractionSlided({ index: s.track.details.rel, title, action: 'dragged' })
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef, items, isMd]
  )

  return (
    <div data-style-context={backgroundColor} className={cx(styles.component, backgroundColor && styles.hasBackgroundColor)}>
      <ContainerMd>
        <div className={styles.containerInner}>
          <div className={styles.top}>
            {title && <HeadingXl layoutClassName={styles.title} h='2'>{title}</HeadingXl>}
            {loaded && isMd && (
              <Controls
                onNext={() => {
                  trackInteractionSlided({ index: currentSlide + 1, title, action: 'clicked' })
                  instanceRef.current.moveToIdx(currentSlide + 1, true)
                }}
                onPrevious={() => {
                  instanceRef.current.moveToIdx(currentSlide - 1, true)
                  trackInteractionSlided({ index: currentSlide - 1, title, action: 'clicked' })
                }}
                {...{ isMd, instanceRef, currentSlide }}
              />
            )}
          </div>

          <div ref={sliderRef} className={cx('keen-slider', styles.slider)}>
            {items && items.filter(x => x && x.ref).map((item, index) => {
              const { button, ref: { intro, hero } } = item
              const { image, title } = hero || {}
              const href = determineDocumentPathSync({ document: item.ref, routeMap })
              return (
                <Slide
                  key={index}
                  {...{ title, intro, image, button, currentSlide, href, isMd, dragged }}
                  layoutClassName={cx(styles.slide, index === 0 && styles.firstSlide)}
                  onClick={handleClick}
                  id={index}
                />
              )
            })}
          </div>
          {loaded && !isMd && instanceRef.current && (
            <Controls
              layoutClassName={styles.controlsMobile}
              onNext={() => {
                trackInteractionSlided({ index: currentSlide, title, action: 'clicked' })
                instanceRef.current.moveToIdx(currentSlide + 1, true)
              }}
              onPrevious={() => {
                instanceRef.current.moveToIdx(currentSlide - 1, true)
                trackInteractionSlided({ index: currentSlide, title, action: 'clicked' })
              }}
              {...{ isMd, instanceRef, currentSlide }}
            />
          )}
        </div>
      </ContainerMd>
      <div className={styles.radial} />
    </div>
  )

  function handleClick(e) {
    dragged && e.preventDefault()
    return !dragged
  }
}

function Slide({ image, href, title, intro, id, layoutClassName, isMd, onClick }) {
  const { __ } = useTranslate()
  return (
    <div
      className={cx(styles.componentSlide, !isMd && styles.mobile, layoutClassName, id === 0 && styles.firstItem, 'keen-slider__slide')}>
      <figure className={styles.figure}>
        {image?.asset
          ? <div className={styles.image}><ImageCover aspectRatio={300 / 173} imgProps={{ loading: 'lazy' }} {...{ image }} /></div>
          : <div className={cx(styles.containerDots, id === 0 && styles.firstItem)}><Icon layoutClassName={styles.dots} icon={dotsIcon} /></div>
        }
      </figure>
      {title && <HeadingXs layoutClassName={styles.heading} h='3'>{title}</HeadingXs>}
      {intro && <div className={styles.text}><TextSm>{intro}</TextSm></div>}
      <div className={styles.button}>
        <Button {...{ href }} dataX='cta-in-slider'>{__`slider-read-more`}</Button>
      </div>
      <a
        className={styles.link}
        tabIndex={-1}
        data-x='cta-in-slider'
        {...{ href, onClick }}
      >
        {__`slider-read-more`}
      </a>
    </div>
  )
}

function Controls({ onNext, onPrevious, instanceRef, isMd, currentSlide, layoutClassName = undefined }) {
  return (
    <nav className={cx(styles.componentControls, layoutClassName)}>
      <button
        data-x='goto-previous-slide'
        type='button'
        onClick={onPrevious}
        className={cx(styles.arrow, currentSlide === 0 && styles.disabled)}
        disabled={currentSlide === 0}
      >
        <Icon icon={arrowLeftIcon} />
      </button>
      <button
        data-x='goto-next-slide'
        type='button'
        onClick={onNext}
        className={cx(styles.arrow, (isMd ? currentSlide + 3 : currentSlide + 1) === instanceRef.current.slides.length && styles.disabled)}
        disabled={(isMd ? currentSlide + 3 : currentSlide + 1) === instanceRef.current.slides.length}
      >
        <Icon icon={arrowRightIcon} />
      </button>
    </nav>
  )
}
